import React from 'react'

import { Link } from 'gatsby'

import {Container, Row, Col} from 'reactstrap'

const Footer = () => (
  <footer id="footer" className="d-flex align-items-center h-100">
     <Container>
         <Row>
             <Col md="8">
                 <img src="/assets/cloudlift_logo_on_dark.png" style={{width:100}} alt="Cloudlift GmbH" />
                 <span style={{marginLeft: 20,fontSize:12}}>© Copyright 2020 Cloudlift GmbH. All Rights Reserved. </span>
             </Col>
             <Col md="4">
                 <div className="d-flex justify-content-end">
                     <ul className="list-inline">
                         <li className="list-inline-item"><Link to="/imprint">Impressum</Link></li>
                         <li className="list-inline-item">|</li>
                         <li className="list-inline-item"><Link to="/privacy">Datenschutz</Link></li>
                     </ul>
                 </div>
             </Col>
         </Row>
     </Container>
  </footer>
)

export default Footer
