import React from 'react'
import { Link } from 'gatsby'

import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem } from 'reactstrap';


class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <Navbar color="primary" fixed="top" dark expand="md">
                <Container>
                    <NavbarBrand href="/" >
                        <img src="/assets/cloudlift_logo_on_dark.png" style={{width:200}} alt="Cloudlift GmbH" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Link to="/" className="nav-link">Home</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/about" className="nav-link">Über uns</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/services" className="nav-link">Services</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/contact" className="nav-link">Kontakt</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Menu



