import React from 'react'

import Menu from './menu'

const Header = ({ siteTitle }) => (
  <div id="header">
      <Menu siteTitle={siteTitle}/>
  </div>
)

export default Header
