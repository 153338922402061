import React from 'react'

import {
    Container,
} from 'reactstrap';

const Cloud = ({ title }) => (
    <div className="cloud-hero">
        <Container>
            <h1 className="text-center">{title}</h1>
        </Container>
        <div id="clouds">
            <div className="cloud big"></div>
            <div className="cloud small"></div>
            <div className="cloud big"></div>
            <div className="cloud small"></div>
            <div className="cloud big"></div>
            <div className="cloud small"></div>
            <div className="cloud small"></div>
            <div className="cloud small"></div>
            <div className="cloud big"></div>
            <div className="cloud small"></div>
            <div className="cloud small"></div>
        </div>
    </div>
)


export default Cloud



