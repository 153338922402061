import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Header from './header'
import Footer from './footer'


// import theme
import '../scss/theme.scss'

// font awesome icons
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/all.css'

// layout style with css modules
import layoutStyles from './layout.module.css'

const Layout = ({ children, title }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={`${data.site.siteMetadata.title} - ${title} `}
          meta={[
            { name: 'description', content: 'Cloudlift GmbH' },
            { name: 'keywords', content: 'cloudlift' },
          ]}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        <main role="main" className={layoutStyles.main}>
          {children}
        </main>
        <Footer/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
